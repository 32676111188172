<template>
  <div>
    <div
      v-if="!rows.length"
      class="d-flex justify-content-center mb-1"
    >
      <div
        class="text-center"
      >
        <h5>{{ $t('widgets.nowidgets') }}</h5>
        <p>{{ $t('widgets.addwidgetbutton') }}</p>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="primary"
          class="btn-icon mb-1"
          :to="{ path: `/users/${$route.params.userId}/sites/${$route.params.siteId}/widgets/create`}"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          {{ $t('widgets.widgetadd') }}
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="widgets-table"
    >
      <!-- search input -->
      <div class="custom-search flex-wrap ">
        <div class="total">
          <strong>{{ $t('widgets.widgettotal') }}: {{ totals }}</strong>
        </div>
        <div class="table-filters">
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t('form.search') }}</label>
            <b-input-group>
              <b-form-input
                ref="searchInput"
                :value="searchTerm"
                :placeholder=" $t('form.search')"
                type="text"
                class="d-inline-block"
                @keyup.enter="searchTerm = $event.target.value"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="searchTerm = $refs.searchInput.vModelValue"
                >
                  <feather-icon
                    icon="SearchIcon"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              :disabled="disableButton"
              variant="primary"
              class="btn-icon"
              :to="{ path: `/users/${$route.params.userId}/sites/${$route.params.siteId}/widgets/create`}"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
              {{ $t('widgets.widgetadd') }}
            </b-button>
          </div>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'status', type: 'asc'}
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        style-class="vgt-table striped"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Status'"
            class="text-nowrap"
          >
            {{ $t('message.tableHeader.status') }}
          </span>
          <span
            v-else-if="props.column.label ==='Name'"
            class="text-nowrap"
          >
            {{ $t('widgets.widgettitle') }}
          </span>
          <span
            v-else-if="props.column.label ==='Type'"
            class="text-nowrap"
          >
            {{ $t('campaigns.type') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('message.tableHeader.action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
          >
            <b-form-checkbox
              :disabled="disableButton"
              :checked="props.row.status === 'active' ? true : false"
              name="check-button"
              switch
              @change="changeStatus(props.row._id)"
            />
          </span>

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'title'"
            class="text-nowrap"
          >
            <span class="d-flex justify-content-between">
              <router-link :to="'/users/'+ $route.params.userId +'/sites/'+ $route.params.siteId +'/widgets/'+ props.row._id + '/stats/'">
                {{ props.row.title }}
              </router-link>
            </span>
          </span>

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'type'"
            class="text-nowrap"
          >
            <span class="d-flex justify-content-between">
              {{ $t(`selects.widgettype.${props.row.type}`) }}
            </span>
          </span>
          <span
            v-if="props.column.field ==='widget_code'"
            class="text-nowrap"
          >
            <b-button
              v-clipboard:copy="widgetCode(props.row.site_id, props.row._id, props.row)"
              v-clipboard:success="onCopyW"
              v-clipboard:error="onError"
              v-b-tooltip.hover.top="$t('widgets.copybutton')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-sm btn-icon"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </span>
          <span
            v-if="props.column.field ==='interstitial_code'"
            class="text-nowrap"
          >
            <b-button
              v-if="props.row.interstitial && props.row.interstitial.active"
              v-clipboard:copy="interstitialCode(props.row.site_id, props.row._id, props.row)"
              v-clipboard:success="onCopyI"
              v-clipboard:error="onError"
              v-b-tooltip.hover.top="$t('widgets.copybutton')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="btn-sm btn-icon"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                right
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="'/users/'+ $route.params.userId +'/sites/'+ $route.params.siteId +'/widgets/'+ props.row._id + '/edit/'"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>{{ $t('form.edit') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="'/users/'+ $route.params.userId +'/sites/'+ $route.params.siteId +'/widgets/'+ props.row._id + '/stats/'"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>{{ $t('Statistics') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="authUserData.role.includes('admin')"
                  variant="danger"
                  @click="deleteEntity('widgets', props.row._id, props.row.title)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('form.delete') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          {{ setTotals(props.total) }}
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('message.pagelength') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20','40']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BPagination, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import widgetCode from '@/views/dashboard/widgets/widgetCode'
import interstitialCode from '@/views/dashboard/widgets/interstitialCode'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    VueGoodTable,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      widgetCode,
      interstitialCode,
      authUserData: getUserData(),
      loading: true,
      pageLength: 20,
      totals: 0,
      dir: false,
      columns: [
        {
          label: 'Status',
          field: 'status',
          width: '3%',
        },
        {
          label: 'ID',
          field: '_id',
          hidden: true,
          sortable: false,
        },
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Widget code',
          field: 'widget_code',
          sortable: false,
          width: '140px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Interstitial code',
          field: 'interstitial_code',
          sortable: false,
          width: '160px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    disableButton() {
      let disabled = false
      if (!this.authUserData.role.includes('admin')) {
        const activeRows = this.rows.filter(row => row.status === 'active')
        const countActiveRows = activeRows.length
        if (countActiveRows >= 4) {
          disabled = true
        }
      }
      return disabled
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {},
  methods: {
    changeStatus(id) {
      const widget = this.rows.find(p => p._id === id)
      widget.status = widget.status === 'inactive' ? 'active' : 'inactive'
      if (widget.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setWidget(id, widget)
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            widget.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setWidget(id, widget)
        })
      }
    },
    onCopyW() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Widget code copied',
          icon: 'BellIcon',
        },
      })
    },
    onCopyI() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Interstitial code copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy widget code!',
          icon: 'BellIcon',
        },
      })
    },
    deleteEntity(entity, id, title) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${title}?`,
        text: this.$t('swal.deletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteEntity(entity, id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$emit('refresh')
            }
          })
        }
      })
    },
    setTotals(count) {
      this.totals = count
    },
  },
}
</script>
<style>
.widgets-table td:first-child,
.widgets-table td:last-child {
  text-align: center!important;
}
</style>
